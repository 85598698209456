<template>
<transition @enter="slideInBottom" @leave="slideOutBottom" :css="false" mode="out-in">
  <div v-if="showOverlay === 'newDefinitionForm'" class="w-full h-auto md:h-full fixed bottom-0 md:inset-0 z-30 flex flex-row justify-center items-center pointer-events-none text-new-black text-arial">
    <div class="w-full md:w-auto md:min-w-1/3 md:max-w-80 h-auto md:h-auto bg-new-yellow rounded-t-3xl md:rounded-3xl p-5 pb-10 md:p-12 pointer-events-auto">
      <div class="md:hidden text-xl cursor-pointer" @click="hideForm">schließen</div>
      <div class="mt-2 md:mt-0 font-faro font-bold text-3xl">Begriff vorschlagen</div>
      <div v-if="!submitted" class="mb-5 mt-2">Dir fehlt ein Begriff im Glossar? Trage hier den Begriff und die dazugehörige Definition ein und wir fügen ihn schnellstmöglich zum Glossar hinzu. Vielen Dank für Deine Mitarbeit.</div>
      <div v-else  class="mb-5 mt-2">{{ submissionText }}</div>

      <form v-if="!submitted" @submit.prevent="submitForm" class="flex flex-col justify-center items-center">
        <input v-model="begriff" type="text" placeholder="Begriff"  class="w-full appearance-none outline-none bg-white text-new-black h-12 mb-4 px-2 rounded-lg" required/>
        <input v-model="definition" type="text" placeholder="Definition" class="w-full appearance-none outline-none bg-white text-new-black h-12 mb-4 px-2 rounded-lg"/>
        <input type=submit value="Begriff vorschlagen" class="text-lg w-full text-white bg-new-black flex flex-col justify-center items-center rounded-lg shadow-lg p-5 cursor-pointer select-none">
      </form>

    </div>
  </div>
</transition>
</template>

<script>
// Mixins
import slideInOutBottom from '../../../mixins/animations/slideInOutBottom.vue';
import iosVibrations from '../../../mixins/iosVibrations.vue';

export default {
  name: 'newDefinitionForm',
  mixins: [slideInOutBottom, iosVibrations],

  data() {
    return {
      submitted: false,
      begriff: '',
      definition: '',
      submissionText: '',
    };
  },

  computed: {
    showOverlay() {
      return this.$store.state.layout.showOverlay;
    },
    me() {
      return this.$store.state.profile.me;
    },
    createFormSubmission() {
      return this.$store.state.createFormSubmission;
    },
    currentCourse() {
      return this.$store.state.settings.currentCourse;
    },
  },

  watch: {
    showOverlay() {
      if (this.showOverlay === null) {
        this.submitted = false;
        this.begriff = '';
        this.definition = '';
      }
    },
  },

  methods: {
    hideForm() {
      this.iosVibrate(0);
      this.$store.commit('showOverlay', null);
    },

    submitForm() {
      this.createFormSubmission({
        user: this.me.email, kurs: this.currentCourse, begriff: this.begriff, definition: this.definition,
      }).then((result) => {
        if (result.error) {
          this.submitted = result.error;
          this.submitted = false;
        } else {
          this.submissionText = 'Danke für deine Hilfe!';
          this.submitted = true;
        }
      });
    },
  },
};
</script>
