<template>
  <div>
    <!-- Components -->
    <darkBackground />
    <newDefinitionForm />
    <formSubmissionMutation />

    <!-- Background -->
    <hafn v-if="appName === 'HAFN'" class="hidden md:block fixed bottom-5 right-5 h-95 w-auto opacity-5 z-000"/>

    <!-- Glossary -->
    <div class="md:pl-24 pt-14 text-new-black font-arial">
      <div class="p-5 md:px-10" :class="{'pt-safe' : parseInt(sat) !== 0}">
        <div class="mt-6 font-faro font-bold text-3xl">Glossar</div>
        <div class="w-full flex flex-row md:block justify-center items-center mt-3">
          <searchBar class="md:hidden h-12 w-1/2"/>
          <div class="ml-2 md:ml-0 w-1/2 md:w-5/12 h-12 bg-white px-2 rounded-xl text-new-black text-arial flex flex-row justify-between items-center cursor-pointer"  @click="openForm">
            <div>Hinzufügen</div>
            <plusCircleSVG />
          </div>
        </div>
        <div v-if="glossary" class="w-full md:w-5/12 mt-3 mb-36">
          <div v-for="letter in alphabet" :key=letter>
            <div v-if="glossary?.some(item => item?.Begriff?.toUpperCase().startsWith(letter))" class="mb-3">
              <div class="text-lg">{{letter}}</div>
              <div class="w-full bg-white rounded-xl shadow-new border border-hi-lighgrey overflow-hidden">
                <div v-for="(item, index) in glossary?.filter(item => item.Begriff.toUpperCase().startsWith(letter))" :key=item :class="{'border-b-2 border-new-yellow' : glossary?.filter(item => item.Begriff.toUpperCase().startsWith(letter)).length > index +1 }">
                  <div class="flex flex-row justify-between items-center">
                    <div class="w-full p-3 cursor-pointer flex flex-col justify-start items-start" @click="selectItem(item.Begriff)"
                    :class="{'font-bold' : selectedItem === item.Begriff}">{{item.Begriff}}</div>
                    <chevronDown class="transition duration-200 ease-in-out text-new-black w-8 h-8 flex-shrink-0 p-1.5 mr-3 origin-center"
                    :class="{'transform rotate-180' : selectedItem === item.Begriff}"
                    @click="selectItem(item.Begriff)" />
                  </div>
                  <div v-if="selectedItem === item.Begriff" class="mt-3 font-normal px-3 pb-2">
                    <tipTap :content="item?.Definition" displayType="clean" />
                  </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <queryGlossary />
  </div>
</template>


<script>
// Mixins
import iosVibrations from '../../mixins/iosVibrations.vue';
import detectNotch from '../../mixins/detectNotch.vue';

// Strapi
import queryGlossary from './strapi/queryGlossary.vue';
import formSubmissionMutation from './strapi/formSubmissionMutation.vue';

// Components
import searchBar from '../navbar/components/searchBar.vue';
import tipTap from '../../symbols/tipTap.vue';
import darkBackground from '../../symbols/darkBackground.vue';
import newDefinitionForm from './components/newDefinitionForm.vue';

// SVG
import hafn from '../../assets/svg/hafn.vue';
import plusCircleSVG from '../../assets/svg/plusCircle.vue';
import chevronDown from '../../assets/svg/chevronDown.vue';

export default {
  name: 'glossary',
  mixins: [iosVibrations, detectNotch],
  components: {
    queryGlossary, searchBar, tipTap, darkBackground, newDefinitionForm, plusCircleSVG, formSubmissionMutation, hafn, chevronDown,
  },

  data() {
    return {
      appName: process.env.VUE_APP_NAME,
      alphabet: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
      definition: '',
      selectedItem: null,
    };
  },

  computed: {
    searchString() {
      return this.$store.state.layout.searchString;
    },
    unfilteredGlossary() {
      return this.$store.state.glossary.glossary;
    },
    glossary() {
      if (this.searchString !== '') {
        const filteredGlossary = [];
        this.unfilteredGlossary.forEach((item) => {
          if (item.Begriff.toLowerCase().includes(this.searchString.toLowerCase())) {
            filteredGlossary.push(item);
          }
        });
        return filteredGlossary;
      }
      return this.unfilteredGlossary;
    },
    currentCourseID() {
      return this.$store.state.settings.currentCourseID;
    },
  },

  methods: {

    selectItem(item) {
      this.iosVibrate(0);
      if (this.selectedItem === item) {
        this.selectedItem = null;
      } else {
        this.selectedItem = item;
      }
    },

    openForm() {
      this.iosVibrate(0);
      this.$store.commit('showOverlay', 'newDefinitionForm');
    },

  },
};

</script>
